export default [
	{  // 生产任务列表
		path: 'task_list',
		name: 'm_production_task',
		component: () => import('@/pages/production/task_list')
	},
	{  // 生产报工列表
		path: 'work_list',
		name: 'm_production_work',
		component: () => import('@/pages/production/work_list')
	},
	{  // 绩效报工
		path: 'work_report',
		name: 'm_worke_report',
		meta: {
			title: '绩效报工'
		},
		component: () => import('@/pages/production/work_report')
	},
	{  // 订单派工
		path: '/order/task/:id',
		name: 'm_production_task_new',
		meta: {
			title: '订单派工'
		},
		props: route => route.params,
		component: () => import('@/pages/production/task_edit')
	},
	{  // 绩效报工记录
		path: 'worker_record_list',
		name: 'm_worker_record',
		component: () => import('@/pages/production/worker_record_list')
	}
];