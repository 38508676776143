import Search from './module/search.vue'
import SearchItem from './module/search_item.vue'
import Operate from './module/operate.vue'
import State from './public/state.vue'

var comps = [
	Search,
	SearchItem,
	Operate,
	State
]

// 导出组件
export default {
	install: function(Vue) {
		comps.forEach(comp => {
			Vue.component(comp.name, comp);
		})
    }
}