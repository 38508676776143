export default [
	{  // 产品列表
		path: 'product_list',
		name: 'm_product',
		component: () => import('@/pages/basic/product_list')
	},
	{  // 颜色列表
		path: 'color_list',
		name: 'm_color',
		component: () => import('@/pages/basic/color_list')
	},
	{  // 物料列表
		path: 'material_list',
		name: 'm_material',
		component: () => import('@/pages/basic/material_list')
	},
	{  // 客户列表
		path: 'customer_list',
		name: 'm_customer',
		component: () => import('@/pages/basic/customer_list')
	},
	{  //品牌管理
		path: 'brand_list',
		name: 'm_brand',
		component: () => import('@/pages/basic/brand_list')
	},
	{  //仓库管理
		path: 'warehouse_list',
		name: 'm_warehouse',
		component: () => import('@/pages/basic/warehouse_list')
	},
	{  //机台管理
		path: 'machine_list',
		name: 'm_machine',
		component: () => import('@/pages/basic/machine_list')
	},
	{  // 商品类目管理
		path: 'product_type_list',
		name: 'm_product_type',
		component: () => import('@/pages/basic/product_type_list')
	},
	{  // 班组管理
		path: 'team_list',
		name: 'm_team',
		component: () => import('@/pages/basic/team_list')
	},
	{  // 员工管理
		path: 'employee_list',
		name: 'm_employee',
		component: () => import('@/pages/basic/employee_list')
	},
	{  // 字典管理
		path: 'dic_list',
		name: 'm_dic',
		component: () => import('@/pages/basic/dic_list')
	},
	{  // 工序管理
		path: 'processes_list',
		name: 'm_processes',
		component: () => import('@/pages/basic/processes_list')
	},
	{  // 工艺路线管理
		path: 'route_list',
		name: 'm_route',
		component: () => import('@/pages/basic/route_list')
	},
	{  // BOM组件信息
		path: 'bom/module',
		name: 'm_bom_module',
		component: () => import('@/pages/basic/product_bom_detail.vue')
	},
	{  // 委托生产商信息
		path: 'manufacture/list',
		name: 'm_iconsigned_mfg',
		component: () => import('@/pages/basic/manufacture_list.vue')
	}
];