import store from '../store';

import productionRoutes from './module/production';  // 生产模块
import systemRoutes from './module/system';  // 系统模块
import basicRoutes from './module/basic';  // 基础模块
import orderRoutes from './module/order';  // 订单模块
import inventoryRoutes from './module/inventory';  // 库存模块
import dataRoutes from './module/data';  // 统计查询模块
import financeRoutes from './module/finance';  // 财务模块
import wmsRoutes from './module/wms';  // 仓储模块

export default [{
	path: '/login',
	name: 'login',
	component: () => import('@/pages/login/index'),
	beforeEnter: (to, from, next) => {
		if (store.state.session) {
			next('/');
		} else {
			next();
		}
	}
}, {
	path: '/',
	component: () => import('@/pages/index'),
	children: [
		...productionRoutes,
		...systemRoutes,
		...basicRoutes,
		...orderRoutes,
		...inventoryRoutes,
		...dataRoutes,
		...financeRoutes,
		...wmsRoutes,
		{
			path: '',
			name: 'home',
			component: () => import('@/pages/home/index')
		}, {
			path: '404',
			component: () => import('@/pages/error/404')
		}
	]
}, {
	path: '/doc',
	component: () => import('@/pages/doc/index')
}, {
	path: '*',
	redirect: '/'
}];