export default [
	{  // 用户列表
		path: 'user_list',
		name: 'm_user',
		component: () => import('@/pages/system/user_list')
	},
	{  // 角色列表
		path: 'role_list',
		name: 'm_role',
		component: () => import('@/pages/system/role_list')
	}, {  // 新建角色
		path: 'role_add',
		name: 'role_add',
		meta: {
			title: '新建角色'
		},
		component: () => import('@/pages/system/role_add')
	}, {  // 编辑角色
		path: 'role_edit/:id',
		name: 'role_edit',
		meta: {
			title: '编辑角色'
		},
		props: route => route.params,
		component: () => import('@/pages/system/role_edit')
	}, {  // 新建打印模板
		path: 'template_add/:type',
		name: 'template_add',
		meta: {
			title: '新建打印模板'
		},
		props: route => route.params,
		component: () => import('@/pages/system/template_add')
	}, {  // 编辑打印模板
		path: 'template_edit/:id',
		name: 'template_edit',
		meta: {
			title: '编辑打印模板'
		},
		props: route => route.params,
		component: () => import('@/pages/system/template_edit')
	}
];