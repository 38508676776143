
export default [
	{  // 入库单列表
		path: 'in_storage_list',
		name: 'm_instorage',
		component: () => import('@/pages/wms/in_storage_list')
	},
	{  // 创建入库单
		path: 'in_storage/add',
		name: 'm_instorage_add',
		meta: {
			title: '新增入库单'
		},
		component: () => import('@/pages/wms/in_storage_add')
	},{  // 编辑入库单
		path: 'in_storage/edit/:id',
		name: 'm_instorage_edit',
		meta: {
			title: '编辑入库单'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/in_storage_edit')
	},{  // 详情入库单
		path: 'in_storage/detail/:id',
		name: 'm_instorage_detail',
		meta: {
			title: '入库单详情'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/in_storage_detail')
	},
	{  // 出库单列表
		path: 'out_storage_list',
		name: 'm_outstorage',
		component: () => import('@/pages/wms/out_storage_list')
	},
	{  // 创建出库单
		path: 'out_storage/add',
		name: 'm_outstorage_add',
		meta: {
			title: '新增出库单'
		},
		component: () => import('@/pages/wms/out_storage_add')
	},{  // 编辑入出库单
		path: 'out_storage/edit/:id',
		name: 'm_outstorage_edit',
		meta: {
			title: '编辑出库单'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/out_storage_edit')
	},{  // 详情出库单
		path: 'out_storage/detail/:id',
		name: 'm_outstorage_detail',
		meta: {
			title: '出库单详情'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/out_storage_detail')
	},
	{  // 盘点单列表
		path: 'inventory_list',
		name: 'm_inventory',
		component: () => import('@/pages/wms/inventory_list')
	},
	{  // 创建盘点单
		path: 'inventory/add',
		name: 'm_inventory_add',
		meta: {
			title: '新增盘点单'
		},
		component: () => import('@/pages/wms/inventory_add')
	},{  // 编辑入盘点单
		path: 'inventory/edit/:id',
		name: 'm_inventory_edit',
		meta: {
			title: '编辑盘点单'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/inventory_edit')
	},{  // 详情盘点单
		path: 'inventory/detail/:id',
		name: 'm_inventory_detail',
		meta: {
			title: '盘点单详情'
		},
		props: route => route.params,
		component: () => import('@/pages/wms/inventory_detail')
	},{  // 【委外订单】列表
		path: 'iconsigned/order',
		name: 'm_iconsigned_order',
		component: () => import('@/pages/wms/iconsigned_order_list')
	},{  // 创建【委外订单】
        path: 'iconsigned/order/add',
        name: 'm_iconsigned_order_add',
        meta: {
            title: '新增委外订单'
        },
        component: () => import('@/pages/wms/iconsigned_order_add')
    },{  // 编辑【委外订单】
        path: 'iconsigned/order/edit/:id',
        name: 'm_iconsigned_order_edit',
        meta: {
            title: '编辑委外订单'
        },
        props: route => route.params,
        component: () => import('@/pages/wms/iconsigned_order_edit')
    },{  // 【委外订单】详情
        path: 'iconsigned/order/detail/:id',
        name: 'm_iconsigned_order_view',
        meta: {
            title: '委外订单详情'
        },
        props: route => route.params,
        component: () => import('@/pages/wms/iconsigned_order_detail')
    }
];