<template>
	<div class="m-operate">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'mOperate'
	}
</script>

<style lang="stylus" scoped>
	.m-operate {
		padding: 10px 12px;
		
		> .c-button:nth-child(n+2) {
			margin-left: 10px;
		}
	}
</style>
