<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	import getLodop from '@/assets/script/lib/LodopFuncs.js'
	import {mapState} from 'vuex'
	
	export default {
		name: 'App',
		
		computed: {
			...mapState(['systemConfig']),
		},
		
		watch: {
			systemConfig() {
				this.updateWebInfo();
			}
		},
		
		created() {
			this.updateWebInfo();
			this.$store.dispatch('getSystemConfig');
		},
		
		methods: {
			updateWebInfo() {
				document.title = this.systemConfig.system_name || '生产管理系统';
			},
			
			lodopPrint(data) {
				let LODOP = getLodop(message => {
					this.$confirm({
						type: 'warn',
						message,
						buttonName: '我知道了',
						cancelButton: false
					});
				});
				
				if (LODOP) {
					this.request({
						url: '/print_out/get_print_info',
						data: {
							billtype_id: data.billtype_id,
							business_key: data.number,
							template_id: data.templateId
						},
						loading: true,
						success: data => {
							LODOP.SET_SHOW_MODE('PREVIEW_IN_BROWSE', true);
							eval(data.PRINT_ORDER
								.replace(/\r?\n/g,'')
								.replace(/,"/g,",'")
								.replace(/",/g,"',")
								.replace(/\("/g,"('")
								.replace(/"\);/g,"');")
							);
							LODOP.SET_PRINT_MODE('AUTO_CLOSE_PREWINDOW', 1);
							
							LODOP.PREVIEW();
							LODOP.On_Return = (TaskID, Value) => { 
								if (Value == '1') {
									this.$message({
										message: '打印成功'
									})
								}
							};
						}
					})
				}
			}
		}
	}
</script>

<style lang="stylus">
	@import './assets/style/reset';
	@import './assets/style/fonts';
	
	.c-module-content {
		> .c-title {
			padding: 0 20px;
		}
	}
	
	.c-table {
		.c-input {
			height: 25px;
			
			input {
				padding: 0 5px;
				font-size: 12px;
			}
		}
		
		.c-select {
			height: 25px;
			
			&-main {
				padding-left: 5px;
			}
			
			&-current {
				font-size: 12px;
			}
		}
	}
	
	.c-table table thead tr th {
		padding: 0 5px;
		line-height: 30px;
	}
	.c-table table tbody tr td {
		padding: 5px;
		line-height: 16px;
		font-size: 12px;
	}
</style>