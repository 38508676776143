/* 
	<template>
		// 使用state标签，定义字典名称dict、值value
		<state dict="dictName" value="1"></state>
		// 使用异步数据定义async属性
		<state dict="dictName" async value="1"></state>
		
		// 下拉框、单复选框直接使用下面引入的选项数据
		<c-select :options="dictName"></c-select>
		<c-radio-group :options="dictName"></c-radio-group>
		<c-checkbox-group :options="dictName"></c-checkbox-group>
	</template>

	<script>
		// 获取本地选项数据
		// 导入mapState方法
		import {mapState} from 'vuex'
		export default {
			computed: {
				// 获取dictName选项数据
				...mapState(['dictName'])
			}
		}
		
		// 获取异步选项数据
		// 导入asyncMapState方法
		import {asyncMapState} from '@/store'
		export default {
			computed: {
				// 获取dictName选项数据
				...asyncMapState(['dictName'])
			}
		}
	</script>
 */

/* 支持颜色
 * gray 灰色
 * blue 蓝色
 * red 红色
 * green 绿色
 * yellow 黄色
 * orange 橙色
 */

export default {
	dictName: [{
		name: '状态1',
		value: 1,
		color: 'blue'
	}, {
		name: '状态2',
		value: 2,
		color: 'red'
	}]
}