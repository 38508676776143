import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routes from './routes';

Vue.use(VueRouter);

Vue.prototype.navigateTo = function(link, open) {
	if (open) {
		window.open(this.$router.resolve(link).href);
	} else {
		this.$router.push(link, () => {});
	}
};
Vue.prototype.navigateBack = function(n = -1) {
	this.$router.go(n);
};
Vue.prototype.redirectTo = function(link) {
	window.$app.getSys().closeAlivePage();
	this.navigateTo(link);
};
Vue.prototype.redirectBack = function() {
	window.$app.getSys().closeAlivePage();
};

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes
});

const WHITE_LIST = [
	'/doc',
	'/login'
]

router.beforeEach((to, form, next) => {
	if (!to.matched.length) {
		next('/404');
		return;
	}
	if (!WHITE_LIST.includes(to.path)) {
		if (!store.state.session) {
			next('/login');
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router