<template>
	<span v-if="data" :style="{'color': color[data.color] || data.color}">{{$t(data.name)}}</span>
</template>

<script>
	import {mapState} from 'vuex'
	
	export default {
		name: 'state',
		
		props: {
			dict: String,
			async: Boolean,
			value: [Number, String]
		},
		
		data() {
			return {
				color: {
					gray: '#999999',
					blue: '#0018FF',
					red: '#ED0000',
					green: '#0EA05D',
					yellow: '#FFAE00',
					orange: '#FF5400'
				}
			}
		},
		
		computed: {
			...mapState(['dictData', 'asyncDictData']),
			
			compDictData() {
				if (this.async) {
					return this.asyncDictData[`${this.dict}Data`];
				} else {
					return this.dictData[`${this.dict}Data`];
				}
			},
			
			data() {
				return this.compDictData ? this.compDictData[this.value] : {name: this.value};
			}
		},
		
		created() {
			if (this.async && this.dict && !this.compDictData) {
				this.$store.dispatch('getAsyncDict', this.dict);
			}
		}
	}
</script>