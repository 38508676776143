<template>
	<div class="m-search">
		<div class="m-search-handle flex-center-cross">
			<c-button color="main" size="search" @click="search">查询</c-button>
			<c-button size="search" @click="clear">重置条件</c-button>
		</div>
		<div class="m-search-content">
			<c-form ref="form" style="padding: 0">
				<div class="flex-wrap">
					<slot></slot>
				</div>
			</c-form>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mSearch',
		
		methods: {
			search() {
				this.$emit('search', this.get());
			},
			
			set(data) {
				this.$refs.form.set(data);
			},
			
			get() {
				return this.$refs.form.get();
			},
			
			clear() {
				this.$refs.form.clear();
			}
		}
	}
</script>

<style lang="stylus">
	.m-search {
		padding: 10px 7px 10px 12px;
		
		+ .m-operate {
			margin-top: -10px;
		}
		
		&-handle {
			margin-bottom: 4px;
			
			.c-button {
				margin-right: 9px;
			}
		}
		
		&-item {
			width: 310px;
			height: 32px;
			border-radius: 2px;
			margin-right: 5px;
			margin-top: 5px;
			background: #F2F4F7;
			
			&[colspan="2"] {
				width: 625px
			}
			
			&:focus-within {
				outline: 1px solid #0060EF;
			}
			
			label {
				margin: 0 12px;
				font-size: 12px;
				color: #666;
			}
			
			.c-input,
			.c-select,
			.c-datetime {
				height: 32px;
				border: none;
				background: none;
			}
			.c-select-current {
				font-size: 12px;
			}
			
			.c-checkbox-group {
				margin-top: 2px;
			}
			.c-checkbox span {
				font-size: 12px;
			}
		}
	}
</style>
