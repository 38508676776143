<template>
	<div class="m-search-item flex-center" :colspan="colspan">
		<label>{{label}}</label>
		<div class="flex-grow">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mSearchItem',
		
		props: {
			label: String,
			colspan: {
				type: [String, Number],
				default: 1
			}
		}
	}
</script>

<style lang="stylus">
	.m-search {
		
	}
</style>
