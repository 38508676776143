export default [
	{  // 产品库存列表
		path: 'inventory_product_list',
		name: 'm_inventory_product',
		component: () => import('@/pages/inventory/product_list')
	},
	{  // 物料库存列表
		path: 'inventory_material_list',
		name: 'm_inventory_material',
		component: () => import('@/pages/inventory/material_list')
	}
];