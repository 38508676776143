import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {cryptoJs} from '@deanwu/vue-component-library'
import dictList from './dict.js'

const dictData = {};
for (let key in dictList) {
	dictData[`${key}Data`] = stateSerialize(dictList[key]);
}
function stateSerialize(data) {
	let json = {};
	data.forEach(item => {
		if (typeof item.value == 'object') {
			item.value.forEach(i => {
				json[i] = item;
			});
		} else {
			json[item.value] = item;
		}
	});
	
	return json;
}

Vue.use(Vuex);

let app = new Vue();
const Store = new Vuex.Store({
	state: {
		...dictList,
		dictData: {
			...dictData
		},
		asyncDictList: {},
		asyncDictData: {},
		session: '',
		userInfo: {},
		authList: [],  // 权限
		navList: [],  // 导航菜单
		systemConfig: {},
		sizeConfig: [],//尺码数量配置
		specConfig: []//颜色及尺码配置
	},

	mutations: {
		updateAsyncDict(state, data) {
			Vue.set(state.asyncDictList, data.dict, data.data);
			Vue.set(state.asyncDictData, `${data.dict}Data`, stateSerialize(data.data));
		},
		
		setSession(state, data) {
			state.session = data;
		},
		
		// 设置用户信息
		setUserInfo(state, data) {
			state.userInfo = data;
		},
		
		// 设置权限列表
		setAuth(state, data) {
			state.authList = data;
		},
		
		// 设置导航菜单
		setNav(state, data) {
			// data.unshift({
			// 	id: 1,
			// 	menu_name: '工作台',
			// 	menu_unique_key: 'home',
			// 	menu_ico: 'tf-nav-home'
			// });
			state.navList = data;
		},
		
		// 设置系统配置
		setSystemConfig(state, data) {
			state.systemConfig = data;
		},
		
		setSizeConfig(state, data) {
			state.sizeConfig = data;
		},
		
		setSpecConfig(state, data) {
			state.specConfig = data;
		}
	},

	actions: {
		// 获取权限列表
		getPowers(store) {
			app.request({
				url: '/store/Custserve/getAuthNode',
				success(data) {
					store.commit('setPowers', data.auth_node);
				}
			});
		},
		
		getAsyncDict(store, dict) {
			store.commit('updateAsyncDict', {
				dict: dict,
				data: []
			});
			
			app.request({
				url: '/basic/dic/info_list',
				data: {
					dic_type_code: dict
				},
				success: data => {
					store.commit('updateAsyncDict', {
						dict: dict,
						data: Array.from(data, item => {
							return {
								name: item.name,
								value: item.code,
								data: item
							}
						})
					});
				}
			})
		},
		
		// 获取系统配置
		getSystemConfig(store) {
			app.request({
				url: '/sys/config',
				success(data) {
					store.commit('setSystemConfig', data);
				}
			});
		}
	},

	plugins: [createPersistedState({
		key: 'session',
		paths: ['session', 'userInfo', 'navList', 'authList'],
		storage: {
			getItem(key) {
				try {
					return JSON.parse(cryptoJs.AES.decrypt(localStorage.getItem(key), 'session').toString(cryptoJs.enc.Utf8));
				} catch(e) {
					return undefined;
				}
			},
			
			setItem(key, val) {
				localStorage.setItem(key, cryptoJs.AES.encrypt(JSON.stringify(val), 'session').toString());
			},
			
			removeItem(key) {
				localStorage.removeItem(key);
			}
		}
	})]
});
export default Store

const asyncMapState = function(data) {
	const res = {};
	data.forEach(dict => {
		res[dict] = {
			get() {
				if (!Store.state.asyncDictList[dict]) {
					Store.dispatch('getAsyncDict', dict);
				}
				
				return Store.state.asyncDictList[dict];
			},
			set() {}
		}
	});
	return res;
}
export {
	asyncMapState
}
