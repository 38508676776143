export default [
	{  // 订单列表
		path: 'order_list',
		name: 'm_order',
		component: () => import('@/pages/order/order_list')
	},
	{  // 创建订单
		path: 'order/create',
		name: 'm_create_order',
		component: () => import('@/pages/order/order_add')
	},{  // 编辑订单
		path: 'order/edit/:id',
		name: 'm_edit_order',
		meta: {
			title: '编辑订单'
		},
		props: route => route.params,
		component: () => import('@/pages/order/order_edit')
	},{  // 订单详情
		path: 'order/detail/:id',
		name: 'm_order_detail',
		meta: {
			title: '订单详情'
		},
		props: route => route.params,
		component: () => import('@/pages/order/order_detail')
	}
];