import { error } from 'stylus/lib/functions';
import Vue from 'vue'
import './aliyun-sdk.min'
import './oss-js-upload'

var _token, _ossUpload;
var app = new Vue();

function getToken() {
	return new Promise(resolve => {
		app.request({
			url: '/oss/token',
			success: function(data) {
				_token = data;
				_ossUpload = new OssUpload({
					bucket: data.bucketName,
					endpoint: data.host,
					// 如果文件大于 chunkSize 则分块上传, chunkSize 不能小于 100KB 即 102400
					chunkSize: 1024 * 500, // 分片大小 500kb
					// 分块上传的并发数
					concurrency: 1,
					/**
					 * 注意: 虽然使用 accessKeyId 和 secretAccessKey 可以进行上传, 但是存在泄露风险,
					 * 因此强烈建议使用下面的 STS token 只有在确认不会出现泄露风险的情况下, 才使用 aliyunCredential
					 */
					stsToken: data
				});
				resolve(true);
			},
			fail: () => {
				resolve(false);
			}
		});
	});
}

function getSuffix(filename) {
	var pos = filename.lastIndexOf('.')
	var suffix = ''
	if (pos != -1) {
		suffix = filename.substring(pos + 1)
	}
	return suffix;
}

function getUuidFilename(filename) {
	var len = 20;// 20长度
	var radix = 16;// 16进制
	var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	var uuid = [], i;
	radix = radix || chars.length;
	if (len) {
		for (i = 0; i < len; i++)
			uuid[i] = chars[0 | Math.random() * radix];
	} else {
		var r;
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	var suffix = getSuffix(filename);
	var uuid = uuid.join('')+"."+suffix;
	return uuid.toLowerCase();
}

/**
 * 生成随机数文件夹名称
 * @param filename
 * @returns {string}
 */
function getNewfolder() {
	var len = 20;// 20长度
	var radix = 16;// 16进制
	var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	var uuid = [], i;
	radix = radix || chars.length;
	if (len) {
		for (i = 0; i < len; i++)
			uuid[i] = chars[0 | Math.random() * radix];
	} else {
		var r;
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	var uuid = uuid.join('');
	return uuid.toLowerCase();
}

/**
 * @param {Function} opt.success [单个文件上传回调]
**/
async function upload(files, opt) {
	if (files.length == 0) return;
	
	if (!_token && await getToken() === false) {
		return;
	}
	
	var filesCount = files.length;
	var errorCount = 0;
	var successCount = 0;
	
	var fruit = new Array(filesCount);
	
	files.forEach((item, i) => {
		var file = item.file;
	
		file.id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
		if (false) { //默认将文件重命名
			file.file_real_name = getUuidFilename(file.name);
		} else {
			file.file_real_name = getNewfolder() + "/" +file.name;
		}
		file.file_path = _token.endpoint + _token.key + file.file_real_name;
	
		_ossUpload.upload({
			file: file, // 必传参数, 需要上传的文件对象
			key: _token.key + file.file_real_name, // 必传参数, 文件上传到 oss 后的名称, 包含路径
			maxRetry: 30, //上传失败后重试次数
			/*
			 * OSS支持4个 HTTP
			 * RFC2616(https://www.ietf.org/rfc/rfc2616.txt)协议规定的Header
			 * 字段：
			 * Cache-Control、Expires、Content-Encoding、Content-Disposition。
			 * 如果上传Object时设置了这些Header，则这个Object被下载时，相应的Header值会被自动设置成上传时的值
			 * 可选参数
			 */
			headers: {
				'CacheControl': 'public',
				'Expires': '',
				'ContentEncoding': '',
				'ContentDisposition': '',
				// oss 支持的 header, 目前仅支持 x-oss-server-side-encryption
				'ServerSideEncryption': ''
			},
	
			onprogress: function(e, id) {
				var rate = parseInt((parseFloat(e.loaded) / parseFloat(e.total)) * 100);
				item.progress(rate);
			},
	
			oncomplete: function(res, file) {
				item.success(file.file_path);
				fruit[i] = file;
				successCount++;
				if (--filesCount == 0) {
					complete();
				}
			},
	
			onerror: function(evt, file) {
				item.fail();
				errorCount++;
				if (--filesCount == 0) {
					complete();
				}
			}
		});
	});

	function complete(success, fail) {
		if (successCount) {
			opt.success(
				Array.from(fruit.filter(item => !!item), item => {
					return {
						path: item.file_path,
						fileObj: item
					}
				})
			);
		}
		
		app.$message({
			message: !errorCount ? '上传成功' : !successCount ? '上传失败' : `上传完成，失败${errorCount}个`
		})
	}
}

export default upload