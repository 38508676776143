var CreatedOKLodop7766 = null;
// var commonParam = {
//     'http_str' : 'http',
// };
//====判断是否需要安装CLodop云打印服务器:====
function needCLodop() {
	return true;
};

//====页面引用CLodop云打印必须的JS文件：====
if (needCLodop()) {
	var head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;
	var oscript = document.createElement("script");
	// if(commonParam['http_str']=='https')
	// {
	// oscript.src = "https://localhost:8443/CLodopfuncs.js";
	oscript.src = "http://localhost:8000/CLodopfuncs.js";
	head.insertBefore(oscript, head.firstChild);
	// }else{
	// oscript.src = commonParam['http_str']+"://localhost:8000/CLodopfuncs.js?priority=1";
	oscript.src = "http://localhost:8000/CLodopfuncs.js?priority=1";
	head.insertBefore(oscript, head.firstChild);

	//引用双端口(8000和18000）避免其中某个被占用：
	oscript = document.createElement("script");
	// oscript.src = commonParam['http_str']+"://localhost:18000/CLodopfuncs.js?priority=0";
	oscript.src = "http://localhost:18000/CLodopfuncs.js?priority=0";
	head.insertBefore(oscript, head.firstChild);
	// }
};

//====获取LODOP对象的主过程：====
function getLodop(error) {
	var strHtmInstall =
		"打印控件未安装！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/install_lodop32.exe' target='_self'>点击这里执行安装</a>，安装后请刷新页面或重新进入。";
	var strHtmUpdate =
		"打印控件需要升级！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/install_lodop32.exe' target='_self'>点击这里执行升级</a>，升级后请重新进入。";
	var strHtm64_Install =
		"打印控件未安装！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/install_lodop64.exe' target='_self'>点击这里执行安装</a>，安装后请刷新页面或重新进入。";
	var strHtm64_Update =
		"打印控件需要升级！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/install_lodop64.exe' target='_self'>点击这里执行升级</a>，升级后请重新进入。";
	var strHtmFireFox = "（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）";
	var strHtmChrome = "（如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）";
	var strUncompleted = "打印插件加载中，请稍后再试";
	var strCLodopInstall =
		"CLodop云打印服务(localhost本地)未安装启动！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>点击这里执行安装</a>，安装后请刷新页面。";
	var strCLodopUpdate =
		"CLodop云打印服务需升级！<a style='color:#00f' href='//tkoss.oss-cn-hangzhou.aliyuncs.com/lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>点击这里执行升级</a>，升级后请刷新页面。";
	var LODOP;
	try {
		var isIE = (navigator.userAgent.indexOf('MSIE') >= 0) || (navigator.userAgent.indexOf('Trident') >= 0);
		if (needCLodop()) {
			try {
				LODOP = getCLodop();
			} catch (err) {
				console.log(err);
			};
			if (!LODOP && document.readyState !== "complete") {
				error(strUncompleted);
				return false;
			};
			if (!LODOP) {
				error(strCLodopInstall);
				return false;
			} else {
				if (CLODOP.CVERSION < "2.1.6.3") {
					error(strCLodopUpdate);
					return false;
				};
			};
		} else {
			var is64IE = isIE && (navigator.userAgent.indexOf('x64') >= 0);
			//=====如果页面有Lodop就直接使用，没有则新建:==========
			if (CreatedOKLodop7766 == null) {
				LODOP = document.createElement("object");
				LODOP.setAttribute("width", 0);
				LODOP.setAttribute("height", 0);
				LODOP.setAttribute("style", "position:absolute;left:0px;top:-100px;width:0px;height:0px;");
				if (isIE) LODOP.setAttribute("classid", "clsid:2105C259-1E0C-4534-8141-A753534CB4CA");
				else LODOP.setAttribute("type", "application/x-print-lodop");
				document.documentElement.appendChild(LODOP);
				CreatedOKLodop7766 = LODOP;
			} else {
				LODOP = CreatedOKLodop7766;
			}
			
			//=====Lodop插件未安装时提示下载地址:==========
			if ((LODOP == null) || (typeof(LODOP.VERSION) == "undefined")) {
				if (navigator.userAgent.indexOf('Chrome') >= 0) {
					error(strHtmChrome);
				} else if (navigator.userAgent.indexOf('Firefox') >= 0) {
					error(strHtmFireFox);
				} else if (is64IE) {
					error(strHtm64_Install);
				} else {
					error(strHtmInstall);
				}
				return false;
			};
		};
		if (LODOP.VERSION < "6.2.1.8") {
			if (!needCLodop()) {
				if (is64IE) error(strHtm64_Update);
				else error(strHtmUpdate);
			};
			return false;
		};
		//===如下空白位置适合调用统一功能(如注册语句、语言选择等):===
		LODOP.SET_LICENSES("", "F4011263D7E17DEAE4642765D883CAF4", "", "B75C0516FF311112B760715A8C6C6D12");
		LODOP.SET_LICENSES("THIRD LICENSE", "", "", "73F696D9F4FC45E02954895AF39207A6");
		//===========================================================
		return LODOP;
	} catch (err) {
		alert("getLodop出错:" + err);
	};
};

export default getLodop;