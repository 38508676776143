
export default [
	{  // 出入库记录列表
		path: 'in_out_record_list',
		name: 'm_data_in_out',
		component: () => import('@/pages/data/in_out_record_list')
	},
	{  // 订单生产进度列表
		path: 'order_production_list',
		name: 'm_data_order_production',
		component: () => import('@/pages/data/order_production_list')
	}
];