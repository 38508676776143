import Vue from 'vue';
import store from './store';
import ossUpload from '@/assets/script/lib/oss/upload'
import {cookie} from '@deanwu/vue-component-library'

let app = new Vue();

// Alpha [开发环境]; Beta [测试环境]; Preview [预发布环境]; Online [正式环境]
const RE = "Online";
const SERVER_ADDR = (re => {
	switch(re) {
		case 'Alpha':
			return '/develop';
		case 'Beta':
			return '/test';
		case 'Preview':
			return '/pre';
		case 'Online':
			return '/api';
	}
})(RE);

var loginTimeoutConfirm = false;

export default {
	request: {
		md5Key: 'V52kPpLS6msz7y3NfZUZ9OKFYeoGysMOeMSPdcObi6onvgmgKKEP5gk3s2omhDDOV3T6SYitc5bKUEzZECnEuN61YvObbu2SD7InNlnWeB8j7M195swDhAYjEnLAI71M',
		enKey: 'b2bc2dcff9074d84',
		deKey: '83fe4c23467507cd',
		log: RE != 'Online' || !!cookie.get('debug'),
		baseUrl: SERVER_ADDR,
		dataFormatter(data) {
			data.session = store.state.session;
			return data;
		},
		response(data, requestQueue) {
			if (data.code == 405 || data.code == 403) {
				if (!loginTimeoutConfirm) {
					loginTimeoutConfirm = true;
					app.$confirm({
						message: '登录状态已失效，请重新登录',
						buttonName: '重新登录',
						cancelButton: false,
						resolve: () => {
							$app.$store.commit('setSession', '');
							$app.navigateTo('/login');
							loginTimeoutConfirm = false;
						}
					});
				}
				for (let i in requestQueue) {
					requestQueue[i].abort();
				}
				return false;
			}
		}
	},
	
	uploadFile(files, success) {
		ossUpload(files, {
			success
		});
	},
	
	theme: {
		main: '#3D7FFF',
		mainHover: '#4D8FFF',
		lesser: '#3D7FFF',
		lesserHover: '#4D8FFF',
		adorn: ''
	},
	
	style: {
		unitBorder: '',
		unitHoverBorder: '',
		unitActiveBorder: '',
		unitDisabled: '',
		cutLine: '#eee',
		
		buttonSize: {
			normal: {
				'height': '30px',
				'padding': '0 8px'
			},
			search: {
				'height': '28px',
				'border-radius': '2px',
				'padding': '0 10px',
				'font-size': '12px'
			}
		},
		buttonColor: {
			normal: {
				normal: '#ccc',
				font: '#333',
				hover: '#0060EF'
			}
		},
		
		table: {
			showColBorder: true,  // 是否显示列的边框
			headBorder: '#D3DCED',  // 表头边框色
			headBackground: '#F5F9FE',  // 表头背景色
			rowBorder: '#ddd',  // 行边框色
			rowBorderStyle: 'dashed',  //  行边框类型
			rowBackground: '#fff',  // 行背景色
			rowEvenBackground: '#FAFAFA',
			rowHoverBackground: '#FAFCFE',  // 行移入背景色
			rowHlBackground: '#FAFCFE',  // 行选中背景色
			summedBackground: '#FFFFCC'  // 合计行背景色
		}
	},
	
	empty: {
		normal: {
			icon: require('@/assets/image/empty.png'),
			message: '暂无相关信息'
		},
	},
	
	data: {
		authority() {
			return store.state.authList;
		},
		
		options: {
			//产品分类
			'PRRODUCT_CLASSIFY'() {
				return new Promise(resolve => {
					app.request({
						url: '/product/type/option',
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.type_name,
									value: item.id
								}
							}));
						}
					})
				})
			},
			//品牌
			'BRAND_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/product/brand/option',
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.brand_name,
									value: item.brand_id
								}
							}));
						}
					})
				})
			},
			//班组
			'TEAM_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/dic/team/list',
						data: {
							pageSize: 9999
						},
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.team_name,
									value: item.team_name,
									id: item.id
								}
							}));
						}
					})
				})
			},
			//机台
			'MACHINE_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/dic/machine/list',
						data: {
							pageSize: 9999
						},
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.machine_name,
									value: item.machine_name
								}
							}));
						}
					})
				})
			},
			//所有仓库
			'WAREHOUSE_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/warehouse/list',
						data: {
							pageSize: 9999
						},
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.warehouse_name,
									value: item.id,
									type: item.type
								}
							}));
						}
					})
				})
			},
			//成品仓库
			'PRODUCT_WAREHOUSE_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/warehouse/list',
						data: {
							type: 1,
							pageSize: 9999
						},
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.warehouse_name,
									value: item.id,
									type: item.type
								}
							}));
						}
					})
				})
			},
			//物料仓库
			'WL_WAREHOUSE_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/warehouse/list',
						data: {
							type: 2,
							pageSize: 9999
						},
						success: data => {
							resolve(Array.from(data||[], item => {
								return {
									name: item.warehouse_name,
									value: item.id,
									type: item.type
								}
							}));
						}
					})
				})
			},
			//工艺路线
			'PROCESSES_ROUTE'() {
				return new Promise(resolve => {
					app.request({
						url: '/process_route/list',
						data: {
							pageSize: 9999
						},
						success: data => {
							data.unshift({name:'------无------',id:0})
							resolve(Array.from(data||[], item => {
								return {
									name: item.name ,
									value: item.id
								}
							}));
						}
					})
				})
			},
			//字典分类
			'DIC_TYPE_LIST'() {
				return new Promise(resolve => {
					app.request({
						url: '/basic/dic/type/option',
						success: data => {
							data.push({type_name:'>>>>>自定义<<<<<',id:-1})
							resolve(Array.from(data||[], item => {
								return {
									name: item.type_name,
									value: item.id
								}
							}));
						}
					})
				})
			},
		},
		
		region(type, pid) {
			return new Promise(resolve => {
				app.request({
					url: '/address_library/region',
					data: {
						parent_id: pid
					},
					success: data => {
						resolve(Array.from(data, item => {
							return {
								name: item.name,
								value: item.id
							}
						}))
					}
				});
			})
		}
	}
}