import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import config from './config.js'
import library from '@deanwu/vue-component-library'
import '@deanwu/vue-component-library/dist/vue-component-library.css'
Vue.use(library, config);

import comps from './components/index.js'
Vue.use(comps);

Vue.prototype.$bus = new Vue();

// 获取APP.vue实例
Vue.prototype.getApp = function() {
	return app.$children[0];
};

// 获取pages/index.vue实例
Vue.prototype.getSys = function() {
	return app.$children[0].$children.find(item => item.$options.name == 'Sys');
};

// 登录失效提示
Vue.prototype.loginTimeout = function() {
	if (!window.$loginTimeoutConfirm) {
		window.$loginTimeoutConfirm = true;
		window.$loadingObj && window.$loadingObj.close();
		this.$confirm({
			message: '登录状态已失效，请重新登录',
			buttonName: '重新登录',
			cancelButton: false,
			resolve: () => {
				app.$store.commit('setSession', '');
				app.navigateTo('/login');
				delete window.$loginTimeoutConfirm;
			}
		});
	}
};

Vue.prototype.oss = function(url, w, h) {
	if (!url) return '';
	return `${url}?x-oss-process=image/resize,h_${h * 2},w_${w * 2}/quality,q_80`;
};

Vue.config.productionTip = false;

const app = window.$app = new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
